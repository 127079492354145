<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

// Components
import CGrid from './CGrid.vue'
import COverlay from './COverlay.vue'
import CTitle from './CTitle.vue'

const store = useStore()
const isOpen = computed(() => store.state.keyboardHelp.open)

function onClose () {
  store.commit('closeKeyboardHelp')
}
</script>

<template>
  <COverlay
    :open="isOpen"
    size="large"
    title="Tastaturbefehle"
    closable
    @close="onClose"
  >
    <CGrid min="20em">
      <section>
        <CTitle
          level="3"
          class="mb-3"
        >
          Formulare
        </CTitle>

        <table class="table-plain">
          <tr>
            <td>
              <kbd>Strg</kbd>&nbsp;<kbd>S</kbd> /
              <kbd>⌘</kbd>&nbsp;<kbd>S</kbd>
            </td>
            <td>Speichern</td>
          </tr>
        </table>
      </section>

      <section>
        <CTitle
          level="3"
          class="mb-3"
        >
          Listen
        </CTitle>

        <table class="table-plain">
          <tr>
            <td><kbd>Umschalt</kbd>&nbsp;<kbd>↑</kbd></td>
            <td>Listenelement nach oben verschieben</td>
          </tr>
          <tr>
            <td><kbd>Umschalt</kbd>&nbsp;<kbd>↓</kbd></td>
            <td>Listenelement nach unten verschieben</td>
          </tr>
        </table>
      </section>
    </CGrid>
  </COverlay>
</template>
