// Functions
import sortNumerally from '../../functions/sortNumerally.js'

export const namespaced = true

export const state = {
  config: {},
  categories: {},
  products: {}
}

/**
 * @type {import("vuex").GetterTree<state>}
 */
export const getters = {
  /**
   * Order categories
   * @returns {Array} Ordered category list
   */
  orderedCategories (state) {
    const list = []

    for (const id in state.categories) {
      list.push(state.categories[id])
    }

    return list.sort((a, b) => sortNumerally(a, b, 'sort_index'))
  },
  /**
   * Ordered products grouped by category
   * @returns {Object} Ordered product list
   */
  orderedProducts (state) {
    const list = {}

    // 1. Create object with category keys and empty arrays
    for (const id in state.categories) {
      list[id] = []
    }

    // 2. Assign products to categories
    for (const id in state.products) {
      const product = state.products[id]
      list[product.category].push(product)
    }

    // 3. Sort products within category
    for (const id in list) {
      list[id].sort((a, b) => sortNumerally(a, b, 'sort_index'))
    }

    return list
  }
}

/**
 * @type {import("vuex").MutationTree<state>}
 */
export const mutations = {
  /**
   * Reset state
   */
  reset (state) {
    state.config = {}
    state.categories = {}
    state.products = {}
  },

  /**
   * Update config
   * @param {Object} config Config data
   */
  updateConfig (state, config) {
    for (const item in config) {
      state.config[item] = config[item]
    }
  },

  /**
   * Add single category
   * @param {Object} category Category data
   */
  addCategory (state, category) {
    state.categories[category.id] = category
  },

  /**
   * Remove single category
   * @param {Number} id Category ID
   */
  removeCategory (state, id) {
    delete state.categories[id]
  },

  /**
   * Add single product
   * @param {Object} product Product data
   */
  addProduct (state, product) {
    product.variant_set.sort((a, b) => sortNumerally(a, b, 'sort_index'))
    state.products[product.id] = product
  },

  /**
   * Remove single product
   * @param {Number} id Product ID
   */
  removeProduct (state, id) {
    delete state.products[id]
  },

  /**
   * Add single variant
   * @param {Object} variant Variant data
   */
  addVariant (state, variant) {
    const variants = state.products[variant.product].variant_set

    variants.push(variant)
    variants.sort((a, b) => sortNumerally(a, b, 'sort_index'))
  },

  /**
   * Remove single variant
   * @param {Number} id Variants ID
   */
  removeVariant (state, id) {
    // Loop throught all products…
    for (const productID in state.products) {
      const variants = state.products[productID].variant_set

      // …to find the current variant in the variant list.
      variants.forEach((variant, index) => {
        if (variant.id === id) {
          variants.splice(index, 1)
        }
      })
    }
  }
}

/**
 * @type {import("vuex").ActionTree<state>}
 */
export const actions = {
  /**
   * Save category list
   * @param {Array} categories Category list
   */
  addCategoryList (context, categories) {
    context.state.categories = {}
    context.state.products = {}

    categories.forEach(category => {
      context.commit('addCategory', category)
    })
  },

  /**
   * Save product list
   * @param {Array} products Product list
   */
  addProductList (context, products) {
    context.state.products = {}

    products.forEach(product => {
      context.commit('addProduct', product)
    })
  }
}
