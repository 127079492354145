import store from '../store'

/**
 * Notify about read-only data in maintenance mode.
 */
export default function () {
  store.commit('openModal', {
    title: 'Wartungsarbeiten',
    content: 'Zurzeit werden Wartungsarbeiten am LaborPublisher durchgeführt. Dabei können keine Daten bearbeitet oder Importe gestartet werden.',
    buttons: [{
      text: 'Schreibgeschützt fortfahren',
      color: 'light'
    }]
  })
}
