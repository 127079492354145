<script setup>
import { computed, onBeforeUnmount,ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

// Globals
import enums from '../enums.js'
// Compoents
import CButton from './CButton.vue'
import CHeaderDropdown from './CHeaderDropdown.vue'

const store = useStore()
const router = useRouter()

const modules = computed(() => {
  const bookedModules = store.state.settings.bookedModules

  return [
    {
      title: 'Dashboard',
      path: 'dashboard',
      isBooked: true
    },
    {
      title: 'LaborEditor',
      path: 'laboreditor',
      isBooked: bookedModules[enums.modules.LABOREDITOR]
    },
    {
      title: 'WebPublisher',
      path: 'webpublisher',
      isBooked: bookedModules[enums.modules.WEBPUBLISHER]
    },
    {
      title: 'PrintPublisher',
      path: 'printpublisher',
      status: store.state.status.tasks.printPublisherGenerate,
      isBooked: bookedModules[enums.modules.PRINTPUBLISHER]
    },
    {
      title: 'LaborShop',
      path: 'laborshop',
      isBooked: bookedModules[enums.modules.LABORSHOP]
    },
    {
      title: 'Ringversuchszertifikate',
      path: 'proficiency',
      isBooked: bookedModules[enums.modules.PROFICIENCY]
    }
  ]
})

const isExpanded = ref(false)

function toggleExpanded () {
  isExpanded.value = !isExpanded.value
}

const removeRouterAfterEach = router.afterEach(() => {
  isExpanded.value = false
})

onBeforeUnmount(() => {
  removeRouterAfterEach()
})
</script>

<template>
  <nav
    class="relative flex"
    aria-label="Modulnavigation"
  >
    <CButton
      :aria-expanded="isExpanded"
      :active="isExpanded"
      class="header-modules__toggle br-0"
      align="left"
      @click="toggleExpanded"
    >
      Modul wählen
    </CButton>

    <CHeaderDropdown
      class="header-modules__dropdown"
      :expanded="isExpanded"
    >
      <CButton
        v-for="item in modules"
        :key="item.path"
        :status="item.status"
        :to="{ name: item.isBooked ? item.path : item.path + '-info' }"
        :disabled="!item.isBooked"
        :icon-start="item.path"
        align="left"
        class="br-0"
        :class="{ 'is-new-module': item.path === 'proficiency' }"
      >
        {{ item.title }}
      </CButton>
    </CHeaderDropdown>
  </nav>
</template>

<style lang="scss">
@use "../css/variables";

.header-modules__toggle {
  @media (min-width: variables.$breakpoint-header-menu-dropdown) {
    display: none;
  }
}

.header-modules__dropdown {
  @media (min-width: variables.$breakpoint-header-menu-dropdown) {
    position: static;
    flex-direction: row;
    transform: none;
    opacity: 1;

    box-shadow: none;

    visibility: visible;
  }
}

.is-new-module {
  position: relative;
  padding-right: 2.9em;

  &::after {
    position: absolute;

    content: "[NEU]";
    font-size: 0.85em;

    top: 0.25em;
    right: 0.75em;
  }
}
</style>
