import store from '../store'

/**
 * Notify about new LaborPublisher version.
 * @param {string} version New version number
 */
export default function (version) {
  store.commit('openModal', {
    closable: false,
    title: 'Neue Version',
    content: `Eine neue Version (${version}) des LaborPublishers ist verfügbar.`,
    buttons: [{
      text: 'Update aktivieren',
      color: 'light',
      handler: () => location.reload()
    }]
  })
}
