import * as category from './category.js'
import * as config from './config.js'
import * as product from './product.js'
import * as variant from './variant.js'

export default {
  config,
  category,
  product,
  variant
}
