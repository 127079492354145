import store from '../store'

/**
 * Notify about "broken" connection to backend.
 */
export default function () {
  store.commit('openModal', {
    title: 'Keine Verbindung',
    content: 'Leider konnte keine Verbindung zum LaborPublisher hergestellt werden. Bitte versuchen Sie es später erneut.',
    buttons: [{
      text: 'Neu verbinden',
      color: 'light',
      handler: () => location.reload()
    }]
  })
}
