<script setup>
import { computed, onBeforeUnmount,ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

// Components
import CButton from './CButton.vue'
import CHeaderDropdown from './CHeaderDropdown.vue'

const router = useRouter()
const store = useStore()

const loggedInID = computed(() => {
  return store.state.user.current.id
})

const isExpanded = ref(false)

function toggleExpanded () {
  isExpanded.value = !isExpanded.value
}

function openKeyboardHelp () {
  isExpanded.value = false
  store.commit('openKeyboardHelp')
}

const removeRouterAfterEach = router.afterEach(() => {
  isExpanded.value = false
})

onBeforeUnmount(() => {
  removeRouterAfterEach()
})
</script>

<template>
  <div
    class="header-settings"
    aria-label="Service-Navigation"
  >
    <CButton
      :to="{ name: 'support' }"
      icon-start="help"
      class="br-0"
    >
      Hilfe
    </CButton>

    <CButton
      aria-label="Einstellungen"
      :aria-expanded="isExpanded"
      :active="isExpanded"
      align="left"
      icon="dots-vertical"
      icon-size="1.375"
      class="h-full br-0"
      @click="toggleExpanded"
    />

    <CHeaderDropdown
      :expanded="isExpanded"
      right
    >
      <CButton
        :to="{
          name: 'settings-user-edit',
          params: { id: loggedInID }
        }"
        align="left"
        class="br-0"
      >
        Konto
      </CButton>

      <CButton
        :to="{ name: 'settings' }"
        align="left"
        class="br-0"
        exact-active
      >
        Einstellungen
      </CButton>

      <hr>

      <CButton
        align="left"
        class="br-0"
        @click="openKeyboardHelp"
      >
        Tastaturbefehle
      </CButton>

      <hr>

      <CButton
        :to="{ name: 'logout' }"
        align="left"
        class="br-0"
      >
        Abmelden
      </CButton>
    </CHeaderDropdown>
  </div>
</template>

<style lang="scss">
.header-settings {
  position: relative;
  margin-left: auto;
}
</style>
